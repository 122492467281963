<template>
  <div class="sourceContent">
    <div
      v-for="(val,key) in sourceList"
      :key="key"
      class="sourceContentBor">
      <div class="sourceContentWrap">
        <sourceItem
          :sourceListItemOps="val"
          :key="val.peerId"
          :imgUrlTime='imgUrlTime'
          :class="{clickClass:val.peerId==currentSourceId}"
          :allSourceData="data.allSourceData"
          :currentSocketData="data.allSourceData[val.peerId]&&data.allSourceData[val.peerId].peerId ? data.allSourceData[val.peerId] : val">
        </sourceItem>
      </div>
    </div>
  </div>
</template>
<script>
import sourceItem from './sourceItem'

export default {
  components: {
    sourceItem
  },
  props: {
    data: Object,
    sourceObj: Array,
    imgUrlTime: Object
  },
  data () {
    return {
      sourceList: this.data.list,
      currentSourceId: ''
    }
  },
  watch: {
    data: function (val) {
      this.sourceList = val.list
    }
  },

  mounted () {

  },
  created () {
    this.sourceListSave = [] // 将最新的值更新
    if (this.data.list) {
      this.data.list.map(v => {
        this.sourceListSave.push(v)
      })
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.sourceContentWrap {
  ::v-deep .select-box {
    .el-select-dropdown__wrap {
      max-height: 266px;
    }
  }
}

.clickClass {
  border: 2px solid rgba(51, 171, 79, 1);
}

.boxVIdeo {
  height: 480px;
  width: 100%;
}

.draw-enter-active,
.draw-leave-active {
  transition: all 0.6s ease;
}

.draw-enter,
.draw-leave-to {
  height: 0;
}
@media screen and (max-width: 930px) {
  .sourceContent {
    ::v-deep .sourceItem {
      /*width: 3.34rem;*/
      /*height: 1.9rem;*/
      .thumbnail-view-img {
        height: 100%;
      }
    }
    ::v-deep .sourceContentBor {
      margin: 0 auto;
    }
  }
}
@media (max-width: 745px) {
  ::v-deep .sourceContentBor {
    margin: 0 auto !important;
  }
}
.sourceContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.sourceItemBorder {
  position: relative;
  flex: 1;
  height: 301px;
  width: 375px;
  max-width: 375px;
}

.sourceItemBorder:hover .sourceItem {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.52);
}

.sourceItemBorder:hover .receiverItem {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.52);
}

.videoDown {
  display: block;
  height: 480px;
  width: 99.3% !important;
  background: rgba(1, 1, 1, 0.5);
  /*transition: all ease 0.5s;*/
  float: left;
  margin: 18px 0;
  /* overflow: hidden; */
  margin-top: 5px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.56);
  border-radius: 8px;
  left: 0;
  padding: 0 20px;
  position: relative;
}

.triangle_border_up {
  width: 0;
  height: 0;
  border-width: 0 12px 14px;
  border-style: solid;
  border-color: transparent transparent rgba(1, 1, 1, 0.5); /*透明 透明  灰*/
  position: absolute;
  bottom: -7px;
  left: 152px;
}

.videoDown .leftDistance {
  left: 30px;
}

.marginRight0 {
  margin-right: 0 !important;
}

.videoDownContent {
  clear: both;
  width: 100%;
  height: 0;
  background: rgba(1, 1, 1, 0.5);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.56);
  margin: 9px 0 18px 0;
  padding: 0 20px;
}
</style>
