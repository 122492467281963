<template>
  <div>
    <div class="sourceItem mediaSourceItem">
      <div
        class="thumbnail-view-img"
        @click="showVideo(currentData)"
        :title="sourceListItemOps.peerId">
        <img
          :src="thumImgUrl"
          class="sourceShowImg"
          alt="">
      </div>
      <div
        class="thumbnail-view-info"
        :title="sourceListItemOps.name">
        <span
          class="square left"
          :class="{green:currentData.status == 1,gray:currentData.status == 0,red:currentData.status == 2}"></span>
        <!-- <i class="el-icon-collection-tag pointer f_left" @click='openBookmarkBox'></i> -->
        <i
          class="bookmark_icon pointer f_left"
          :class='["iconfont"]'
          @click='operationBookmarkBox(true)'>{{sourceListItemOps.userBookmarkDevice?'&#xe63b;':'&#xe636;'}}</i>
        <span
          class="iconfont deviceIcon"
          v-html="setIcon(true)"
          :title="setIcon(false)"></span>
        <span class="sourceNameOps">{{sourceListItemOps.name}}</span>
        <!-- media相关 -->
        <div
          class="right shareToggleIcon"
          v-show="selectRIsLive && ifOpenMediaService && mediaLoaded"
          @click="showVideo(currentData, 'media')">
          <span
            class="iconfont i-share"
            :title="$t('lang.i18n_moreShare')">
          </span>
          <span
            v-show="currentSocketData.shareSize && currentSocketData.shareSize != 0"
            class="sharingNum globalNum">{{currentSocketData.shareSize}}</span>
        </div>
      </div>
      <div class="hide">{{currentData}}</div>

    </div>
    <video-control
      v-if="showVideoControl"
      :rid="currentData.livePeerId"
      :currentInfo="currentData"
      :close="showVideo"
      :listItemOps="sourceListItemOps"
      :key="sourceListItemOps.peerId + 'videoBox'"
      :currentSocketData="currentSocketData"
      :mediaBoxOpen="mediaBoxOpen"
      title="sourceControlTitle" />

    <!-- add bookmark -->
    <add-bookmark
      :visible='isShow'
      :lastBookmark='sourceListItemOps.userBookmarkDevice'
      :parentBookmark="sourceListItemOps.parentBookmarkDevice || {}"
      :deviceObj='{id:sourceListItemOps.peerId,type:"Home"}'
      @closeBox='operationBookmarkBox'>
    </add-bookmark>
  </div>

</template>

<script>
import videoControl from '../videoControl'
import { newHomeAnalyze } from '@/assets/js/googleAnalyze/newHome.js'
import { handleSource } from '@/assets/js/shareCommon.js'
import { SHOWDEVICEIMG, SETSOURCEICON } from '@/assets/js/filters'
import AddBookmark from '@/components/addBookmark'
import Bus from '@/assets/js/vueBus'

export default {
  name: 'sourceItem',
  props: {
    sourceListItemOps: {
      type: Object,
      default: () => {
        return {}
      }
    },
    currentSocketData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    imgUrlTime: Object
  },
  components: {
    videoControl,
    AddBookmark
  },
  data () {
    return {
      logo: 'this.src="' + require('@/assets/img/logo_thumbnail.png') + '"', // 如果图片报错使用默认图片,并解决图片闪烁问题
      currentData: this.sourceListItemOps, // 当前的websocket数据
      currentDataBlob: this.sourceListItemOps, // 用于更新blob服务的soket数据,现变成5s一次 防止加载过快 load不下来的情况
      isLoad5s: 0,
      showVideoControl: false,
      videoControlInfo: {},
      // media相关
      selectRIsLive: this.currentSocketData && this.currentSocketData.status == '2', // 选中的R是否正在和当前source进行live
      ifOpenMediaService: this.$store.state.common.baseUrl.mediaControl.home || false,
      mediaLoaded: false,
      mediaBoxOpen: false,
      firstLoadImg: true, // use it and loadtime to load img
      firstLoadTime: 0,
      thumImgUrl: '',
      isShow: {
        bookmark: false
      }
    }
  },
  mounted () {
    // this.queryInfo();
  },
  created () {
    if (this.ifOpenMediaService) this.isCompleteLoad()
  },
  methods: {
    setIcon (flag) {
      return SETSOURCEICON(this.currentSocketData, flag)
    },
    // 打开或关闭bookmark box
    operationBookmarkBox (val) {
      this.isShow.bookmark = val
    },
    loadThumImg () {
      if (this.firstLoadImg) {
        this.firstLoadImg = false
        this.firstLoadTime = new Date().getTime()
        this.thumImgUrl = SHOWDEVICEIMG(this.currentDataBlob)
      } else {
        if (new Date().getTime() - this.firstLoadTime < 5000) return
        this.firstLoadTime = new Date().getTime()
        if (this.sourceListItemOps.status == 2) {
          if (this.imgUrlTime.current[this.currentDataBlob.livePeerId] == this.imgUrlTime.last[this.currentDataBlob.livePeerId] && this.currentDataBlob.type != 'X') {
            return
          }
          if (this.imgUrlTime.current[this.currentDataBlob.peerId] == this.imgUrlTime.last[this.currentDataBlob.peerId] && this.currentDataBlob.type == 'X') {
            return
          }
        } else {
          if (this.imgUrlTime.current[this.currentDataBlob.peerId] == this.imgUrlTime.last[this.currentDataBlob.peerId] && this.sourceListItemOps.status == 1) {
            return
          }
        }
        this.thumImgUrl = SHOWDEVICEIMG(this.currentDataBlob)
      }
    },
    handleList (val) {
      this.currentSocketData = handleSource(this.sourceListItemOps.peerId, this.currentData.livePeerId, this.selectRIsLive, val)
      // console.log(this.currentSocketData);
    },
    isCompleteLoad () {
      if (typeof mediaObj != 'undefined') {
        this.mediaLoaded = true
      } else {
        setTimeout(() => {
          this.isCompleteLoad()
        }, 100)
      }
    },
    showVideo (val, type) {
      this.showVideoControl = !this.showVideoControl
      if (this.showVideoControl) { // 关闭打开弹窗的埋点
        if (this.$createWebsocket.pageRWebsocket) {
          this.$createWebsocket.pageRWebsocket('stop', 'receiverData') // 停止websocket
        }
        newHomeAnalyze('SourceSHowVideo', this.$route.path)
        newHomeAnalyze('newHomeOpenShare', this.$route.path)
      } else {
        Bus.$emit('openListWebsocket')
        newHomeAnalyze('SourceHideVideo', this.$route.path)
      }
      this.mediaBoxOpen = type && type == 'media' && this.showVideoControl
      if (!this.mediaBoxOpen && typeof mediaObj != 'undefined') {
        mediaObj.rId = null
      }
    }
  },
  watch: {
    currentSocketData (val, oldval) {
      // this.isLoad5s ++;
      if (val.peerId) {
        this.currentData = val
        if (this.ifOpenMediaService) {
          this.selectRIsLive = val && val.status == '2'
          this.handleList(val)
        }
        this.currentDataBlob = val
        this.loadThumImg()
      }
    }
  },
  filters: {}
}
</script>

<style lang="less" scoped>
.hide {
  display: none;
}
.sourceNameOps {
  display: inline-block;
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
}
.sourceItem {
  width: 2.6rem;
  height: 1.72rem;
  margin-right: 0.3rem;
  margin-bottom: 0.42rem;
  cursor: pointer;

  .thumbnail-view-img {
    height: 1.45rem;
    background-color: #727b75;
    background: url('../../../assets/img/logo_thumbnail.png') no-repeat center;
    .sourceShowImg {
      width: 100%;
      height: 100%;
    }
  }
  .shareToggleIcon {
    margin: 0 !important;
    .sharingNum {
      // bottom: 10px;
      right: -1px;
    }
  }
  .square {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    margin: 0.06rem 0;
    margin-right: 0.09rem;

    &.green {
      background: #0c6;
    }

    &.red {
      background: #f00;
    }

    &.gray {
      background: #999;
    }
  }
  .deviceIcon {
    font-size: 15px;
    margin: 0 8px 0 6px;
    float: left;
  }

  .right {
    float: right;
    margin-top: 10px;
    margin-right: 5%;
  }

  .liveInfo {
    width: 100%;
  }

  .thumbnail-view-info {
    padding-left: 0.06rem;
    height: 0.27rem;
    line-height: 0.27rem;
    border: 1px solid #666;
    font-size: 0.14rem;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .el-icon-collection-tag {
      margin-top: 6px;
      margin-right: 10px;
      color: #dbdbdb;
    }
  }
}
@media (max-width: 745px) {
  ::v-deep .sourceContentBor {
    margin: 0 auto !important;
  }
}
</style>
